<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280">
    <v-dialog v-model="dialog_object.show" width="600">
      <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1">
          <v-toolbar-title class="toolbar-work-place-title">
            {{ dialog_object.action === 'create' ? 'Створення документів' : 'Видалення документів' }}
          </v-toolbar-title>
        </v-toolbar>
        <div class="pa-2">
          <v-switch hide-details class="ml-3 mt-0 mb-0" color="success"
                    disabled
                    v-model="dialog_object.use_accounting"
                    :label="dialog_object.use_accounting
                        ?  'Без проведення в бухгалтерсьому обліку' : 'Проведення в бухгалтерсьому обліку'"
          ></v-switch>
          <v-list class="pt-3" subheader two-line flat>
            <v-divider></v-divider>
            <v-subheader class="mt-2 mb-2 success--text font-weight-medium"
                         style="height: 30px !important;">
              Оберіть необхідні документи
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item :ripple="false" class="mb-2 mt-2">
                <v-list-item-action>
                  <v-checkbox
                      v-model="documents_crud.act.active"
                      color="success"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content class="grey lighten-4">
                  <v-list-item-title class="px-2 font-weight-medium"
                                     style="font-size: .94rem">
                    Акт виконаних робіт
                  </v-list-item-title>
                  <v-list-item-subtitle class="px-2">
                    <v-row>
                      <v-col cols="6">
                        <v-checkbox
                            label="Вказати дату"
                            :ripple="false"
                            hide-details
                            color="success"
                            :disabled="!documents_crud.act.active"
                            v-model="documents_crud.act.change_date"/>
                      </v-col>
                      <v-col cols="6">
                        <date-component :disabled="!documents_crud.act.change_date" req label="Дата"
                                        v-model="documents_crud.act.date"></date-component>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :ripple="false" class="mb-2">
                <v-list-item-action>
                  <v-checkbox
                      v-model="documents_crud.bill.active"
                      color="success"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content class="grey lighten-4">
                  <v-list-item-title class="px-2 font-weight-medium"
                                     style="font-size: .94rem">
                    Рахунок за надані послуги/виконані роботи
                  </v-list-item-title>
                  <v-list-item-subtitle class="px-2">
                    <v-row>
                      <v-col cols="6">
                        <v-checkbox
                            label="Вказати дату"
                            :ripple="false"
                            hide-details
                            color="success"
                            :disabled="!documents_crud.bill.active"
                            v-model="documents_crud.bill.change_date"/>
                      </v-col>
                      <v-col cols="6">
                        <date-component :disabled="!documents_crud.bill.change_date" req label="Дата"
                                        v-model="documents_crud.bill.date"></date-component>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :ripple="false" class="mb-2">
                <v-list-item-action>
                  <v-checkbox
                      v-model="documents_crud.tax.active"
                      color="success"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content class="grey lighten-4">
                  <v-list-item-title class="px-2 font-weight-medium"
                                     style="font-size: .94rem">
                    Податкова накладна
                  </v-list-item-title>
                  <v-list-item-subtitle class="px-2">
                    <v-row>
                      <v-col cols="6">
                        <v-checkbox
                            label="Вказати дату"
                            :ripple="false"
                            hide-details
                            color="success"
                            :disabled="!documents_crud.tax.active"
                            v-model="documents_crud.tax.change_date"/>
                      </v-col>
                      <v-col cols="6">
                        <date-component :disabled="!documents_crud.tax.change_date" req label="Дата"
                                        v-model="documents_crud.tax.date"></date-component>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click.stop="doActionDialog"
                 class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            Виконати
          </v-btn>
          <v-btn depressed text tile color="grey" @click="closeDialog">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title class="pa-0">
      <document_print v-model="print_object.show_print"
                      :doc_type="print_object.document_type"
                      :doc_id="print_object.document_id"
                      :doc_form="'v1'"
      />
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title>
          Перелік документів
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="mr-2 grey lighten-4" @click.stop="openCreateDialog">
          <v-icon size="26">mdi-plus</v-icon>
        </v-btn>
        <v-btn icon class="grey lighten-4" @click.stop="openDeleteDialog" :disabled="!selected.length">
          <v-icon size="26">mdi-delete</v-icon>
        </v-btn>
        <MainModal
            v-if="modal_object.document_type"
            :main="{ component: modal_object.list_name, title: modal_object.list_title }"
            :button="{buttonHidden: true}"
            :item="Object.assign({}, modal_object)"
            :modal="dialog_id === `${modal_object.document_type}_${modal_object.id}`"
            :full_screen="modal_object.full_screen"
            @updateItemModal="updateItemModal"
        />
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="tableHeaders"
        :items="items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openModal"
    >
      <template v-slot:item.active="{ item }">
        <v-checkbox :ripple="false"
                    :indeterminate="item.document_month !== current_month"
                    indeterminate-icon="mdi-file-lock-outline"
                    :disabled="item.document_month !== current_month"
                    @click.stop="markDocument(item)"
                    hide-details
                    class="mt-0 pt-0"
                    :value="selected.includes(item.row_num)"
                    color="success"></v-checkbox>
      </template>
      <template v-slot:item.icon="{ item }">
        <v-icon :color="getDocumentNameByType(item.document_type, 'icon_color')"
                @click.stop="printDocument(item)"
        >
          mdi-printer
        </v-icon>
      </template>
      <template v-slot:item.month="{ item }">
        <span>
          {{ item.document_month | formatDate('MMMM YYYY') }}
        </span>
      </template>
      <template v-slot:item.date="{ item }">
        <span>
          {{ item.document_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
        </span>
      </template>
      <template v-slot:item.legacy_number="{ item }">
        <span>
          {{ item.legacy_number }}
        </span>
      </template>
      <template v-slot:item.document_type="{ item }">
        <span>
          {{ getDocumentNameByType(item.document_type) }}
        </span>
      </template>
      <template v-slot:item.contract_name="{ item }">
        <span>
          {{ item.contract_name }}
        </span>
      </template>
      <template v-slot:item.document_total="{ item }">
        <span style="font-weight: bold" class="grey--text text--darken-2" >
          {{ item.document_total | formatToFixed | formatNumber }}
        </span>
      </template>
      <template v-slot:item.document_total_tax="{ item }">
        <span style="font-weight: bold" class="grey--text text--darken-2">
          {{ item.document_total_tax | formatToFixed | formatNumber }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapGetters, mapActions} from "vuex";
import {
  CREATE_LEGACY_ACCOUNTING_DOCUMENTS,
  GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT,
  REMOVE_LEGACY_ACCOUNTING_DOCUMENTS
} from "@/store/actions/legacy_documents";
import {getAccountIcon, endOfMonth} from "@/utils/icons";
import {getDocumentObject, getDocumentNameByType} from "@/utils/accounting"
import {QUESTION_SHOW} from "@/store/actions/question";
import {ALERT_SHOW} from "@/store/actions/alert";

const modalDeleteId = 'remove-selected-legacy-documents'

export default {
  name: "HWP_Modal_FlatContract",
  props: {},
  components: {
    MainModal,
    document_print: () => import("@/components/DocumentPrintModalWrapper")
  },
  data() {
    return {
      delete_watcher: null,
      selected: [],
      print_object: {
        show_print: false,
        document_type: null,
        document_id: null
      },
      dialog_id: 0,
      tableHeaders: [
        {text: '', value: 'active', width: 20},
        {text: '', value: 'icon', width: 20},
        {text: 'Місяць', value: 'month'},
        {text: 'Дата', value: 'date'},
        {text: 'Номер', value: 'legacy_number'},
        {text: 'Тип документа', value: 'document_type'},
        {text: 'Договір', value: 'contract_name'},
        {text: 'Всього', value: 'document_total'},
        {text: 'ПДВ', value: 'document_total_tax'},
      ],
      modal_object: {
        id: null,
        document_type: null
      },
      dialog_object: {
        show: false,
        action: 'create',
        use_accounting: false
      },
      documents_crud: {
        act: {active: false, change_date: false, date: null},
        bill: {active: false, change_date: false, date: null},
        tax: {active: false, change_date: false, date: null},
      }
    }
  },
  computed: {
    ...mapGetters({
      items: 'getLegacyAccountingDocuments',
      flat: 'getLegacyFlat',
      current_month: 'getCurrentMonthLegacy',
      modalAnswer: 'question_answer',
      settings: 'getAccountingGeneralSettings'
    })
  },
  methods: {
    ...mapActions({
      fetch_items: GET_LEGACY_ACCOUNTING_DOCUMENTS_BY_FLAT
    }),
    printDocument(payload) {
      this.print_object.document_id = payload.document_id
      this.print_object.document_type = payload.document_type
      this.print_object.show_print = true
    },
    markDocument(payload) {
      if (this.selected.indexOf(payload.row_num) !== -1) {
        this.selected.splice(this.selected.indexOf(payload.row_num), 1)
      } else {
        this.selected.push(payload.row_num)
      }
    },
    getDocumentNameByType,
    doActionDialog() {
      const payload = {...this.documents_crud, flat_id: this.flat.id, accounting_conduct: this.dialog_object.use_accounting}
      this.$store.dispatch(CREATE_LEGACY_ACCOUNTING_DOCUMENTS, payload)
          .then(() => {
            this.closeDialog()
          })
    },
    closeDialog() {
      this.dialog_object.action = ''
      this.dialog_object.show = false

      this.documents_crud.act.change_date = false
      this.documents_crud.bill.change_date = false
      this.documents_crud.tax.change_date = false

      this.documents_crud.act.active = false
      this.documents_crud.bill.active = false
      this.documents_crud.tax.active = false

      this.documents_crud.act.date = endOfMonth(this.current_month)
      this.documents_crud.bill.date = endOfMonth(this.current_month)
      this.documents_crud.tax.date = endOfMonth(this.current_month)
    },
    openCreateDialog() {
      const end_of_month = endOfMonth(this.current_month)
      this.watch_modal_answer()
      this.documents_crud.act.date = end_of_month
      this.documents_crud.bill.date = end_of_month
      this.documents_crud.tax.date = end_of_month

      this.dialog_object.action = 'create'
      this.dialog_object.show = true
      this.dialog_object.use_accounting = this.settings?.legacy_without_accounting?.value || false
    },
    openDeleteDialog() {
      const payload = {
        text: `Підтвердіть вилучення відмічених документів`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    getAccountIcon,
    updateItemModal() {
      this.dialog_id = 0
      this.modal_object = {
        id: null,
        document_type: null
      }
    },
    openModal(row) {
      const doc = getDocumentObject(row.document_type)
      this.modal_object = Object.assign(
          doc, {id: row.document_id, document_type: row.document_type}
      )
      if (doc) {
        this.dialog_id = `${row.document_type}_${row.document_id}`
      } else {
        this.dialog_id = `DOCUMENT_UNDEFINED_NULL`
      }
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  const documents = this.items.filter(i => this.selected.includes(i.row_num)).map(i => {
                    return {
                      'document_type': i.document_type,
                      "document_id": i.document_id,
                      "contractor_id": this.flat.contractor_id,
                      "flat_id": this.flat.id
                    }
                  })
                  this.$store.dispatch(REMOVE_LEGACY_ACCOUNTING_DOCUMENTS, documents)
                      .then(data => {
                        this.$store.dispatch(ALERT_SHOW, {
                          text: `Вилучено ${data.deleted} документ(-ів)`,
                          color: 'secondary'
                        })
                      })
                      .finally(() => {
                        this.selected = []
                      })
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.watch_modal_answer()
    this.fetch_items({flat_id: this.flat.id})
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  }
}
</script>

<style scoped lang="scss">

</style>